<script setup>
import Document from "@tiptap/extension-document";
import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";
import { Editor, EditorContent } from "@tiptap/vue-3";
import { ref, onMounted, onBeforeUnmount } from "vue";
import c3api from "../../../c3api";
import { useToast } from "vue-toastification";
import { useRouter, useRoute } from "vue-router/auto";
import FabButton from "../../../components/FabButton.vue";
import Image from "@tiptap/extension-image";
import pageTitle, { setPageTitle } from "../../../utils/pageTitle";

const toast = useToast();
const router = useRouter();
const route = useRoute();
const editor = ref(null);
const content = ref(null);
const changelogTitle = ref(null);
const pullRequestId = ref(null);
const placeholder = Placeholder.configure({
  placeholder: "Click here and begin typing your changelog...",
});

onMounted(() => {
  getChangelog();
  editor.value = new Editor({
    extensions: [StarterKit, Image, placeholder],
    onUpdate: ({ editor }) => {
      content.value = editor.getHTML();
    },
  });
});

onBeforeUnmount(() => {
  editor.value.destroy();
});

const getChangelog = () => {
  const id = router.currentRoute.value.params.id;
  c3api.get(`/changelogs/${route.params.id}`).then((response) => {
    const changelog = response.data.data;
    changelogTitle.value = changelog.title;
    pullRequestId.value = changelog.pull_request_id;
    content.value = changelog.raw_body;
    editor.value.commands.setContent(changelog.raw_body);
    setPageTitle("Editing " + changelog.title);
  });
};

const updateChangelog = () => {
  const params = {
    title: changelogTitle.value,
    raw_body: content.value,
    pull_request_id: pullRequestId.value,
  };
  c3api.patch(`/changelogs/${route.params.id}`, params).then((response) => {
    toast.success("Changelog updated successfully.");
    router.push(`/changelogs/${route.params.id}`);
  });
};

const uploadImage = async () => {
  let input = document.createElement("input");
  input.type = "file";
  input.onchange = async (_) => {
    // you can use this method to get file and perform respective operations
    let files = Array.from(input.files);
    const formData = new FormData();
    formData.append("file", files[0]);
    const response = await c3api
      .post("/changelogs/upload_image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const image = response.data.data;
        toast.success("Image uploaded successfully.");
        if (image) {
          editor.value.chain().focus().setImage({ src: image.url }).run();
        }
      });
    return response.data.url;
  };
  input.click();
};
</script>

<template>
  <div v-if="editor">
    <div>
      <v-card-title>{{ pageTitle }}</v-card-title>
    </div>
    <v-card class="mt-4 px-4 py-4 mx-2">
      <v-card-text>
        <v-text-field v-model="changelogTitle" label="Enter Changelog Title...">
        </v-text-field>
        <v-text-field v-model="pullRequestId" label="Pull Request ID"> </v-text-field>
      </v-card-text>
    </v-card>
    <v-card class="mt-4 px-4 py-4 mx-2">
      <v-card-text>
        <v-btn flat color="background" class="mr-1 mt-1" @click="uploadImage"
          >image</v-btn
        >
        <v-btn
          flat
          color="background"
          @click="editor.chain().focus().toggleBold().run()"
          :disabled="!editor.can().chain().focus().toggleBold().run()"
          :class="{ 'mr-1 mt-1': true, 'is-active': editor.isActive('bold') }"
        >
          bold
        </v-btn>
        <v-btn
          flat
          color="background"
          @click="editor.chain().focus().toggleItalic().run()"
          :disabled="!editor.can().chain().focus().toggleItalic().run()"
          :class="{ 'mr-1 mt-1': true, 'is-active': editor.isActive('italic') }"
        >
          italic
        </v-btn>
        <v-btn
          flat
          color="background"
          @click="editor.chain().focus().toggleStrike().run()"
          :disabled="!editor.can().chain().focus().toggleStrike().run()"
          :class="{ 'mr-1 mt-1': true, 'is-active': editor.isActive('strike') }"
        >
          strike
        </v-btn>
        <v-btn
          flat
          color="background"
          @click="editor.chain().focus().toggleCode().run()"
          :disabled="!editor.can().chain().focus().toggleCode().run()"
          :class="{ 'mr-1 mt-1': true, 'is-active': editor.isActive('code') }"
        >
          code
        </v-btn>
        <!-- <v-btn color="background" variant="outlined"
    color="grey" @click="editor.chain().focus().unsetAllMarks().run()"
      >clear marks</v-btn
    >
    <v-btn color="background" variant="outlined"
    color="grey" @click="editor.chain().focus().clearNodes().run()"
      >clear nodes</v-btn
    > -->
        <v-btn
          flat
          color="background"
          @click="editor.chain().focus().setParagraph().run()"
          :class="{ 'mr-1 mt-1': true, 'is-active': editor.isActive('paragraph') }"
        >
          p
        </v-btn>
        <v-btn
          flat
          color="background"
          @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
          :class="{
            'mr-1 mt-1': true,
            'is-active': editor.isActive('heading', { level: 2 }),
          }"
        >
          h2
        </v-btn>
        <v-btn
          flat
          color="background"
          @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
          :class="{
            'mr-1 mt-1': true,
            'is-active': editor.isActive('heading', { level: 3 }),
          }"
        >
          h3
        </v-btn>
        <v-btn
          flat
          color="background"
          @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
          :class="{
            'mr-1 mt-1': true,
            'is-active': editor.isActive('heading', { level: 4 }),
          }"
        >
          h4
        </v-btn>
        <!-- <v-btn color="background"

      @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
      :class="{ 'mr-1 mt-1': true, 'is-active': editor.isActive('heading', { level: 5 }) }"
    >
      h5
    </v-btn>
    <v-btn color="background"

      @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
      :class="{ 'mr-1 mt-1': true, 'is-active': editor.isActive('heading', { level: 6 }) }"
    >
      h6
    </v-btn> -->
        <v-btn
          flat
          color="background"
          @click="editor.chain().focus().toggleBulletList().run()"
          :class="{ 'mr-1 mt-1': true, 'is-active': editor.isActive('bulletList') }"
        >
          ul
        </v-btn>
        <v-btn
          flat
          color="background"
          @click="editor.chain().focus().toggleOrderedList().run()"
          :class="{ 'mr-1 mt-1': true, 'is-active': editor.isActive('orderedList') }"
        >
          ol
        </v-btn>
        <v-btn
          flat
          color="background"
          @click="editor.chain().focus().toggleCodeBlock().run()"
          :class="{ 'mr-1 mt-1': true, 'is-active': editor.isActive('codeBlock') }"
        >
          code
        </v-btn>
        <v-btn
          flat
          color="background"
          @click="editor.chain().focus().toggleBlockquote().run()"
          :class="{ 'mr-1 mt-1': true, 'is-active': editor.isActive('blockquote') }"
        >
          blockquote
        </v-btn>
        <v-btn
          flat
          color="background"
          class="mr-1 mt-1"
          @click="editor.chain().focus().setHorizontalRule().run()"
        >
          hr
        </v-btn>
        <v-btn
          flat
          color="background"
          class="mr-1 mt-1"
          @click="editor.chain().focus().setHardBreak().run()"
          >br</v-btn
        >
        <v-btn
          flat
          color="background"
          class="mr-1 mt-1"
          @click="editor.chain().focus().undo().run()"
          :disabled="!editor.can().chain().focus().undo().run()"
        >
          undo
        </v-btn>
        <v-btn
          flat
          color="background"
          class="mr-1 mt-1"
          @click="editor.chain().focus().redo().run()"
          :disabled="!editor.can().chain().focus().redo().run()"
        >
          redo
        </v-btn>
        <div class="mt-10"><EditorContent :editor="editor" /></div></v-card-text
    ></v-card>
  </div>

  <FabButton @click="updateChangelog" label="Update" prepend-icon="mdi-content-save" />
</template>

<style lang="scss" scope>
/* Basic editor styles */
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  p {
    font-size: 1.1rem;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }
  textarea {
    border: none;
    background-color: transparent;
    resize: none;
    outline: none;
    font-size: 1.1rem;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#999, 0.4);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#999, 0.4);
    margin: 2rem 0;
  }
}

/* Placeholder (on every new line) */
.ProseMirror .is-empty::before {
  content: attr(data-placeholder);
  float: left;
  color: #999;
  pointer-events: none;
  height: 0;
}
.ProseMirror-focused {
  outline: none;
}
</style>
